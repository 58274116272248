import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import { Helmet } from 'react-helmet'

import { Layout } from '../components/common'
import { MetaData } from '../components/common/meta'

/**
 * Single post view (/:slug)
 *
 * This file renders a single post and loads all the content.
 *
 */
const Post = ({ data, location }) => {
    const post = data.markdownRemark

    return (
        <>
            <MetaData
                data={data}
                location={location}
                type="article"
            />
            <Helmet>
                <style type="text/css">{`${post.frontmatter.codeinjection_styles || ``}`}</style>
            </Helmet>
            <Layout>
                <div className="container">
                    <article className="content">
                        { post.frontmatter.feature_image && post.frontmatter.feature_image.childImageSharp ?
                            <figure className="post-feature-image">
                                <Img fluid={post.frontmatter.feature_image.childImageSharp.fluid}
                                    alt={ post.frontmatter.title } style={{ marginBottom: `1vw` }}/>
                                {/* <img src={ post.frontmatter.feature_image } alt={ post.title } /> */}
                            </figure> : null }
                        <section className="post-full-content">
                            <img src="/images/icons/tag.svg" alt="Tag" />&nbsp;
                            {post.frontmatter.tags.map(({ frontmatter }) => (
                                <Link key={frontmatter.slug} to={`/tag/${frontmatter.slug}`} style={{ marginRight: `1vw` }}>
                                    {frontmatter.name}
                                </Link>
                            ))}
                            <h1 className="content-title">{post.frontmatter.title}</h1>

                            {/* The main post content */ }
                            <section
                                className="content-body load-external-scripts"
                                dangerouslySetInnerHTML={{ __html: post.html }}
                            />
                        </section>
                    </article>
                </div>
            </Layout>
        </>
    )
}

Post.propTypes = {
    // TODO:
    data: PropTypes.object.isRequired,
    // data: PropTypes.shape({
    //     ghostPost: PropTypes.shape({
    //         codeinjection_styles: PropTypes.object,
    //         title: PropTypes.string.isRequired,
    //         html: PropTypes.string.isRequired,
    //         feature_image: PropTypes.string,
    //     }).isRequired,
    // }).isRequired,
    location: PropTypes.object.isRequired,
}

export default Post

// export const postQuery = graphql`
//     query($slug: String!) {
//         ghostPost(slug: { eq: $slug }) {
//             ...GhostPostFields
//         }
//     }
// `

export const postQuery = graphql`
    query($slug: String!) {
        markdownRemark(frontmatter: { slug: { eq: $slug } }) {
            html
            frontmatter {
                date(formatString: "MMMM DD, YYYY")
                slug
                title
                feature_image {
                    childImageSharp {
                    fluid(maxWidth: 1000, maxHeight: 500) {
                        ...GatsbyImageSharpFluid
                    }
                    }
                }
                author {
                    frontmatter {
                        name
                        profile_image
                        twitter
                        facebook
                        website
                    }
                }
                tags {
                    frontmatter {
                        name
                        slug
                    }
                }
                meta_description
                published_at(formatString: "MMMM DD, YYYY")
                comment,
                id
            }
            excerpt
        }
    }
`
